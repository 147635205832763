import React from 'react';
import { Form, Input } from 'antd';

const FormInput = ({
  name,
  label,
  tooltip,
  hasFeedback,
  size,
  rules = [{ required: false }],
  labelCol,
  disabled = false,
  style,
  inputStyle,
  placeholder,
  validateStatus,
  help,
}) => {
  return (
    <Form.Item
      labelCol={labelCol}
      tooltip={tooltip}
      labelAlign="left"
      name={name}
      label={label}
      rules={rules}
      hasFeedback={hasFeedback}
      style={style}
      validateStatus={validateStatus}
      help={help}
    >
      <Input size={size} disabled={disabled} {...inputStyle} placeholder={placeholder} />
    </Form.Item>
  );
};

export default FormInput;
