import axios from 'axios';
import runtimeEnv from '@mars/heroku-js-runtime-env';
// eslint-disable-next-line import/no-cycle
import authService from './auth.api';

const env = runtimeEnv();

const getHeaders = () => {
  const user = JSON.parse(localStorage.getItem('user'));

  const headers = {
    Accept: 'application/json, text/plain, */*',
    'Content-Type': 'application/json',
  };

  if (user && user.accessToken) {
    headers['x-access-token'] = user.accessToken;
  }

  return headers;
};

const axiosInstance = axios.create({
  baseURL: `${env.REACT_APP_API_URL || window.location.origin}`,
  timeout: 200000,
  headers: getHeaders(),
});

axiosInstance.interceptors.response.use(
  (res) => res,
  (error) => {
    if (error?.response?.status === 401) {
      authService.logout();
      window.location.replace('/login');
    } else if (error?.response?.data?.name === 'ValidationError') {
      const message = error?.response?.data?.details?.params?.map((err) => err?.message).join('/n');
      return Promise.reject(message);
    } else if (!error.response) {
      return Promise.reject(error?.message);
    } else {
      let errorMessage = 'Error!';
      if (typeof error?.response?.data === 'string') {
        errorMessage = error?.response?.data;
      } else if (typeof error?.message === 'string') {
        errorMessage = error?.message;
      }
      return Promise.reject(errorMessage);
    }
    return null;
  },
);

export default axiosInstance;
