/* eslint-disable no-shadow */
import React, { Suspense, useCallback, useState } from 'react';
import { Link, useHistory, useLocation } from 'react-router-dom';
import { Menu, Input, Form, Col, Row, Spin, Layout, Tooltip, Button, Switch, Modal, message } from 'antd';
import {
  LogoutOutlined,
  MenuUnfoldOutlined,
  ShoppingCartOutlined,
  CarFilled,
  FileDoneOutlined,
  UserOutlined,
  CodepenOutlined,
  CodeSandboxOutlined,
  CodepenCircleOutlined,
  SplitCellsOutlined,
  AreaChartOutlined,
  QrcodeOutlined,
  DollarOutlined,
  TeamOutlined,
  MailOutlined,
} from '@ant-design/icons';
import FormInput from 'components/FormInput';
import authService from 'services/auth.api';
import { MENU_ITEMS } from './dashboard-menu.constants';
import logo from '../../assets/logo.png';
import { deleteUrlParam, hasUrlParam, setUrlParam } from '../../shared/url.utils';

const { Header } = Layout;

const URL_PARAMS = {
  addUserOpen: 'addUserOpen',
};

const Dashboard = ({ selectedMenuItem, selectedMenuItemIndex, onLogout, currentUser }) => {
  const [dashboardItem, setDashboardItem] = useState(selectedMenuItem);
  const [checked, setChecked] = useState(localStorage.getItem('darkTheme') === 'true');
  const defaultAddUserModal = hasUrlParam(URL_PARAMS.addUserOpen);
  const [isModalOpen, setIsModalOpen] = useState(defaultAddUserModal);
  const [isLoading, setIsLoading] = useState(false);
  const [form] = Form.useForm();

  const toggleChecked = (value) => {
    setChecked(!!value);
    localStorage.setItem('darkTheme', !!value);
  };

  const history = useHistory();
  const { search } = useLocation();
  const query = new URLSearchParams(search);

  if (!currentUser) {
    window.location.reload();
  }

  const onLogoutClick = useCallback(() => {
    onLogout();
    history.push('/login');
    window.location.reload();
  }, [history, onLogout]);

  const onAddUser = async (values) => {
    const { password, confirmPassword, email, firstName, lastName } = values;
    if (password !== confirmPassword) {
      message.error('Passwords do not match');
    } else {
      setIsLoading(true);
      const response = await authService.signUp(email, firstName, lastName, password);
      const { error } = response;
      setIsLoading(false);
      if (!error) {
        message.success('Successfully registered!');
      } else {
        message.error(error);
      }
    }
  };

  const onShowAddUserModal = () => {
    setIsModalOpen(true);
    setUrlParam(URL_PARAMS.addUserOpen, true);
  };

  const onOk = () => {
    form.submit();
  };

  const onCancel = () => {
    setIsModalOpen(false);
    deleteUrlParam(URL_PARAMS.addUserOpen);
  };

  const renderDashboardItem = () => {
    const DashboardItem = React.lazy(() => import(`/${dashboardItem.module}/${dashboardItem.module}`));

    return <DashboardItem leaseId={query.get('leaseId')} />;
  };

  const menuIcons = {
    transit: <CarFilled />,
    newleases: <ShoppingCartOutlined />,
    lease: <FileDoneOutlined />,
    customers: <UserOutlined />,
    products: <CodepenOutlined />,
    units: <CodeSandboxOutlined />,
    parts: <CodepenCircleOutlined />,
    // asn: <DatabaseOutlined />,
    purchaseOrders: <ShoppingCartOutlined />,
    vendors: <SplitCellsOutlined />,
    reporting: <AreaChartOutlined />,
    discountCodes: <QrcodeOutlined />,
    payments: <DollarOutlined />,
    emails: <MailOutlined />,
    default: <MenuUnfoldOutlined />,
  };

  const renderMenuItem = (menuItem, menuItemIndex) => {
    const onMenuItemClick = () => {
      setDashboardItem(menuItem);
    };

    const isItemSelected = menuItemIndex === selectedMenuItemIndex;
    const classnames = isItemSelected ? 'ant-menu-item-selected' : '';

    return (
      <Menu.Item key={menuItem.key} icon={menuIcons[menuItem.key]} onClick={onMenuItemClick} className={classnames}>
        <Link to={menuItem.key}>{menuItem.label}</Link>
      </Menu.Item>
    );
  };

  return (
    <Row>
      <div
        style={{
          display: 'flex',
          flexDirection: 'row',
          alignItems: 'flex-start',
          justifyContent: 'flex-start',
          width: '100%',
        }}
      >
        <div style={{ width: 170, height: '100vh', position: 'fixed' }}>
          <div className="menuHeader">
            <img className="logo" src={logo} alt="conjure" />
            {process.env.NODE_ENV === 'development' && (
              <>
                <Switch checked={checked} onChange={toggleChecked} checkedChildren="dark" unCheckedChildren="light" />
                {checked && <style>{`html { filter: invert(0.88) brightness(1.4); }`}</style>}
              </>
            )}
          </div>
          <Menu mode="inline">{MENU_ITEMS.map(renderMenuItem)}</Menu>
        </div>
        <div style={{ width: '100%', marginLeft: 170 }}>
          <Header>
            <div className="wrapper">
              <h1>Dashboard</h1>
              <h4
                style={{
                  marginLeft: 'auto',
                  padding: '0 12px',
                }}
              >
                <Link to="/reset-password">{currentUser.fullName}</Link>
              </h4>
              <Tooltip title="Add user">
                <Button
                  style={{
                    margin: '0px 8px 6px 0px',
                  }}
                  onClick={onShowAddUserModal}
                  shape="circle"
                  icon={<TeamOutlined />}
                />
              </Tooltip>
              <Tooltip title="Log out">
                <Button
                  style={{
                    margin: '0px 8px 6px 0px',
                  }}
                  onClick={onLogoutClick}
                  shape="circle"
                  icon={<LogoutOutlined />}
                >
                  <Link to="login" />
                </Button>
              </Tooltip>
            </div>
          </Header>
          <Modal
            title="Add user"
            visible={isModalOpen}
            onOk={onOk}
            onCancel={onCancel}
            width="840px"
            confirmLoading={isLoading}
          >
            <Form
              layout="vertical"
              name="registrationForm"
              size="small"
              form={form}
              initialValues={{
                remember: true,
              }}
              onFinish={onAddUser}
            >
              <Row justify="space-between">
                <Col span={11}>
                  <FormInput
                    label="First name"
                    name="firstName"
                    rules={[
                      {
                        required: true,
                        message: 'Please input your first name!',
                      },
                    ]}
                  />
                  <FormInput label="Last name" name="lastName" />
                  <FormInput
                    label="Email"
                    name="email"
                    style={{ justifyContent: 'space-between' }}
                    rules={[
                      {
                        required: true,
                        type: 'email',
                        message: 'Please input valid email!',
                      },
                    ]}
                  />
                </Col>
                <Col span={11}>
                  <Form.Item
                    label="Password"
                    name="password"
                    rules={[
                      {
                        required: true,
                        message: 'Please input your password!',
                      },
                    ]}
                  >
                    <Input.Password />
                  </Form.Item>

                  <Form.Item
                    label="Confirm password"
                    name="confirmPassword"
                    rules={[
                      {
                        required: true,
                        message: 'Please confirm your password!',
                      },
                    ]}
                  >
                    <Input.Password />
                  </Form.Item>
                </Col>
              </Row>
            </Form>
          </Modal>
          <Suspense fallback={<Spin />}>{renderDashboardItem()}</Suspense>
        </div>
      </div>
    </Row>
  );
};

export default React.memo(Dashboard);
