export const MENU_ITEMS = [
  {
    key: 'transit',
    module: 'Transit',
    label: 'Transit',
  },
  {
    key: 'newleases',
    module: 'NewLeases',
    label: 'New Leases',
  },
  {
    key: 'lease',
    module: 'Leases',
    label: 'Leases',
  },
  {
    key: 'customers',
    module: 'Customers',
    label: 'Customers',
  },
  {
    key: 'products',
    module: 'Products',
    label: 'Products',
  },

  {
    key: 'units',
    module: 'Units',
    label: 'Units',
  },
  {
    key: 'parts',
    module: 'Parts',
    label: 'Parts',
  },
  {
    key: 'purchaseOrders',
    module: 'PurchaseOrders',
    label: 'PO & Shipments',
  },
  // {
  //   key: 'asn',
  //   module: 'ASN',
  //   label: 'ASN',
  // },
  {
    key: 'vendors',
    module: 'Vendors',
    label: 'Vendors',
  },
  // {
  //   key: 'reporting',
  //   module: 'Reporting',
  //   label: 'Reporting',
  // },
  {
    key: 'discountCodes',
    module: 'DiscountCodes',
    label: 'Discount Codes',
  },
  {
    key: 'payments',
    module: 'Payments',
    label: 'Payments',
  },
  {
    key: 'emails',
    module: 'Emails',
    label: 'Emails',
  },
];

export default {};
