import React from 'react';
import { Switch, Route, BrowserRouter, Redirect } from 'react-router-dom';
import { Layout } from 'antd';
import 'antd/dist/antd.min.css';

import Account from 'pages/Account';
import RegistrationForm from 'pages/Register';
import Login from 'pages/Login';
import Dashboard from 'pages/Dashboard/Dashboard';
import { MENU_ITEMS } from 'pages/Dashboard/dashboard-menu.constants';

import ProtectedRoute from 'features/ProtectedRoute';

import useLocalStorage from 'shared/hooks/useLocalStorage';

import logo from 'assets/logo.png';

import './App.css';

const { Header, Content } = Layout;

const App = () => {
  const [currentUser, , logout] = useLocalStorage('user');

  const renderDashboard = (selectedMenuItem, selectedMenuItemIndex) => {
    return (
      <div className="dashboard">
        <Dashboard
          onLogout={logout}
          currentUser={currentUser}
          selectedMenuItem={selectedMenuItem}
          selectedMenuItemIndex={selectedMenuItemIndex}
        />
      </div>
    );
  };

  const renderMenuItem = (menuItem, menuIndex) => {
    const partRoute = menuItem.key;

    return (
      <ProtectedRoute
        key={menuItem.key}
        path={`/dashboard/${partRoute.toLowerCase()}`}
        exact
        isAuthenticated={!!currentUser}
      >
        {renderDashboard(menuItem, menuIndex)}
      </ProtectedRoute>
    );
  };

  const renderLogin = () => {
    return (
      <>
        <Header>
          <div className="wrapper">
            <img className="logo" src={logo} alt="conjure" />
          </div>
        </Header>
        <div className="login">
          <Login userLoggedIn />
        </div>
      </>
    );
  };

  const renderResetPassword = () => {
    return (
      <>
        <Header>
          <div className="wrapper">
            <img className="logo" src={logo} alt="conjure" />
          </div>
        </Header>
        <div className="login">
          <Account userLoggedIn />
        </div>
      </>
    );
  };

  const renderRegister = () => {
    return (
      <>
        <Header>
          <div className="wrapper">
            <img className="logo" src={logo} alt="conjure" />
          </div>
        </Header>
        <div className="login">
          <RegistrationForm />
        </div>
      </>
    );
  };

  return (
    <div>
      <Layout>
        <Content>
          <BrowserRouter>
            <Switch>
              {MENU_ITEMS.map(renderMenuItem)}
              <Route path="/login">{currentUser ? <Redirect to="/dashboard/newleases" /> : renderLogin}</Route>
              <Route path="/sign-up">{renderRegister}</Route>
              <Route path="/reset-password">{renderResetPassword}</Route>

              <Redirect from="/" to="/dashboard/newleases" />
            </Switch>
          </BrowserRouter>
        </Content>
      </Layout>
    </div>
  );
};

export default React.memo(App);
