function changeUrl(url) {
  window.history.pushState(null, '', url);
}

export function setUrlParam(name, value) {
  const url = new URL(window.location);
  url.searchParams.set(name, value);
  if (value) changeUrl(url);
}

export function deleteUrlParam(name) {
  const url = new URL(window.location);
  url.searchParams.delete(name);
  changeUrl(url);
}

export function getUrlParam(name) {
  const url = new URL(window.location);
  return url.searchParams.get(name);
}

export function hasUrlParam(name) {
  const url = new URL(window.location);
  return url.searchParams.has(name);
}

export function deleteAllUrlParams() {
  const url = new URL(window.location);
  const keys = [];
  // eslint-disable-next-line no-restricted-syntax
  for (const key of url.searchParams.keys()) keys.push(key);
  // eslint-disable-next-line no-restricted-syntax
  for (const key of keys) url.searchParams.delete(key);
  changeUrl(url);
}
