import React, { PureComponent, useState } from 'react';
import { Link, useHistory } from 'react-router-dom';
import { Form, Input, Button, Card, Row, Col, message } from 'antd';
import 'antd/dist/antd.min.css';

import FormInput from 'components/FormInput';

import authService from 'services/auth.api';

import logo from 'assets/logo.png';

const AccountForm = () => {
  const [isLoading, setIsLoading] = useState(false);
  const history = useHistory();

  const onFinish = async ({ oldPassword, newPassword, email }) => {
    setIsLoading(true);
    const response = await authService.changePassword(oldPassword, newPassword, email);
    setIsLoading(false);
    const { error } = response;
    if (!error) {
      message.success('Password was reset successfully!');
      setTimeout(() => {
        history.push('/login');
      }, 400);
    } else {
      message.error(error);
    }
  };

  return (
    <Card
      style={{ width: 400, height: 500 }}
      title="Reset password"
      loading={isLoading}
      cover={
        <img
          alt="Log in"
          style={{
            margin: '16px auto 16px auto',
            width: 200,
          }}
          src={logo}
        />
      }
    >
      <Form layout="horizontal" onFinish={onFinish} labelCol={{ span: 8 }}>
        <FormInput
          label="Email"
          name="email"
          style={{ justifyContent: 'space-between', marginBottom: '10px' }}
          rules={[
            {
              required: true,
              type: 'email',
              message: 'Please input valid email!',
            },
          ]}
        >
          <Input />
        </FormInput>

        <Form.Item
          label="Old Password"
          name="oldPassword"
          justify="space-between"
          labelAlign="left"
          style={{ marginBottom: '10px' }}
          rules={[{ required: true, message: 'Please input your old password!' }]}
        >
          <Input.Password />
        </Form.Item>

        <Form.Item
          label="New Password"
          name="newPassword"
          justify="space-between"
          labelAlign="left"
          style={{ marginBottom: '10px' }}
          rules={[{ required: true, message: 'Please input your new password!' }]}
        >
          <Input.Password />
        </Form.Item>

        <Form.Item justify="space-between">
          <Row justify="space-between">
            <Col span={11}>
              <Button style={{ width: '100%' }} htmlType="submit" type="primary">
                Submit
              </Button>
            </Col>
            <Col span={11}>
              <Button style={{ width: '100%' }} type="primary">
                <Link to="/login">Back</Link>
              </Button>
            </Col>
          </Row>
        </Form.Item>
      </Form>
    </Card>
  );
};

class Account extends PureComponent {
  render() {
    return (
      <div>
        <AccountForm />
      </div>
    );
  }
}

export default Account;
