const setItem = (key, value) => {
  localStorage.setItem(key, value);

  window.dispatchEvent(new Event('local-storage-changed'));
};

const getItem = (key) => localStorage.getItem(key);

const removeItem = (key) => {
  localStorage.removeItem(key);

  window.dispatchEvent(new Event('local-storage-changed'));
};

export default {
  setItem,
  getItem,
  removeItem,
};
