import { useState, useEffect, useCallback } from 'react';

import localStorage from 'shared/localStorage';

const useLocalStorage = (key) => {
  const getItemFromLocalStorage = useCallback(() => {
    const item = localStorage.getItem(key);

    return item ? JSON.parse(item) : null;
  }, [key]);

  const [innerValue, setInnerValue] = useState(getItemFromLocalStorage);

  const setValue = useCallback(
    (newInnerValue) => {
      localStorage.setItem(key, JSON.stringify(newInnerValue));
      setInnerValue(newInnerValue);
    },
    [setInnerValue, key],
  );

  const removeValue = useCallback(() => {
    localStorage.removeItem(key);
    setInnerValue(null);
  }, [setInnerValue, key]);

  const onStorageChange = useCallback(() => {
    setInnerValue(getItemFromLocalStorage);
  }, [setInnerValue, getItemFromLocalStorage]);

  useEffect(() => {
    window.addEventListener('storage', onStorageChange);
    window.addEventListener('local-storage-changed', onStorageChange);

    return () => {
      window.removeEventListener('storage', onStorageChange);
      window.removeEventListener('local-storage-changed', onStorageChange);
    };
  }, [onStorageChange]);

  return [innerValue, setValue, removeValue];
};

export default useLocalStorage;
