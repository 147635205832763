import React, { PureComponent, useState } from 'react';
import { Form, Input, Button, Card, Row, Col, message } from 'antd';
import { useHistory, Link } from 'react-router-dom';

import logo from 'assets/logo.png';
import 'antd/dist/antd.min.css';

import FormInput from 'components/FormInput';

import authService from 'services/auth.api';

const Register = () => {
  const [isLoading, setIsLoading] = useState(false);
  const history = useHistory();

  const onFinish = async (values) => {
    const { password, confirmPassword, email, firstName, lastName } = values;
    if (password !== confirmPassword) {
      message.error('Passwords do not match');
    } else {
      setIsLoading(true);
      let response = null;
      try {
        response = await authService.signUp(email, firstName, lastName, password);
      } catch (error) {
        console.log(error);
      }

      const { error } = response || {};
      setIsLoading(false);
      if (!error) {
        message.success('Successfully registered!');
        setTimeout(() => {
          history.push('/login');
        }, 400);
      } else {
        message.error(error);
      }
    }
  };

  return (
    <Card
      style={{ width: 600 }}
      title="Registration"
      loading={isLoading}
      extra={
        <img
          alt="Conjure"
          style={{
            width: 40,
          }}
          src={logo}
        />
      }
    >
      <Form
        layout="vertical"
        name="registrationForm"
        size="small"
        initialValues={{
          remember: true,
        }}
        onFinish={onFinish}
      >
        <Row justify="space-between">
          <Col span={11}>
            <FormInput
              label="First name"
              name="firstName"
              rules={[
                {
                  required: true,
                  message: 'Please input your first name!',
                },
              ]}
            />
            <FormInput label="Last name" name="lastName" />
            <FormInput
              label="Email"
              name="email"
              style={{ justifyContent: 'space-between' }}
              rules={[
                {
                  required: true,
                  type: 'email',
                  message: 'Please input valid email!',
                },
              ]}
            />
          </Col>
          <Col span={11}>
            <Form.Item
              label="Password"
              name="password"
              rules={[
                {
                  required: true,
                  message: 'Please input your password!',
                },
              ]}
            >
              <Input.Password />
            </Form.Item>

            <Form.Item
              label="Confirm password"
              name="confirmPassword"
              rules={[
                {
                  required: true,
                  message: 'Please confirm your password!',
                },
              ]}
            >
              <Input.Password />
            </Form.Item>
          </Col>
        </Row>
        <Form.Item>
          <Row justify="space-between">
            <Col span={11}>
              <Button style={{ width: '100%' }} htmlType="submit" type="primary">
                Submit
              </Button>
            </Col>
            <Col span={11}>
              <Button style={{ width: '100%' }} type="primary">
                <Link to="/login">Back</Link>
              </Button>
            </Col>
          </Row>
        </Form.Item>
      </Form>
    </Card>
  );
};

class RegistrationForm extends PureComponent {
  render() {
    return <Register />;
  }
}

export default RegistrationForm;
