import localStorage from 'shared/localStorage';

// eslint-disable-next-line import/no-cycle
import api from './api';

class AuthService {
  /* Auth */
  async login(email, password) {
    return api
      .post('/api/auth/login', {
        email,
        password,
      })
      .then((response) => {
        const { data } = response || {};
        if (data.accessToken) {
          localStorage.setItem('user', JSON.stringify(response.data));
        }
        return data;
      })
      .catch((error) => ({
        error,
      }));
  }

  async signUp(email, firstName, lastName, password) {
    return api
      .post('api/auth/sign-up', {
        email,
        firstName,
        lastName,
        password,
      })
      .then((response) => {
        const { data } = response || {};
        return data;
      })
      .catch((error) => ({
        error,
      }));
  }

  logout() {
    localStorage.removeItem('user');
  }

  // TODO: only admin users can do this.
  async registerUser(username, email, password) {
    return api.post('/api/user/sign-up', {
      username,
      email,
      password,
    });
  }

  getCurrentUser() {
    return JSON.parse(localStorage.getItem('user'));
  }

  changePassword(oldPassword, newPassword, email) {
    return api
      .post('/api/auth/change-password', {
        oldPassword,
        newPassword,
        email,
      })
      .then((response) => {
        const { data } = response || {};
        return data;
      })
      .catch((error) => ({
        error,
      }));
  }
}

export default new AuthService();
