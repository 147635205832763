import React, { PureComponent, useState } from 'react';
import { Form, Input, Button, Card, Row, Col, message } from 'antd';
import { useHistory, Link } from 'react-router-dom';

import FormInput from 'components/FormInput';

import logo from 'assets/logo.png';
import 'antd/dist/antd.min.css';

import authService from 'services/auth.api';

const LoginForm = () => {
  const [isLoading, setIsLoading] = useState(false);
  const history = useHistory();

  const onFinish = async (values) => {
    const { email, password } = values;
    setIsLoading(true);
    const response = await authService.login(email, password);
    setIsLoading(false);
    const { error } = response;
    if (!error) {
      history.push('/');
      document.location.reload();
    } else {
      message.error(error);
    }
  };

  return (
    <Card
      style={{ width: 400, height: 500 }}
      title="Log In"
      loading={isLoading}
      cover={
        <img
          alt="Log in"
          style={{
            margin: '16px auto 16px auto',
            width: 200,
          }}
          src={logo}
        />
      }
    >
      <Form
        layout="horizontal"
        name="login"
        initialValues={{
          remember: true,
        }}
        labelCol={{ span: 7 }}
        onFinish={onFinish}
      >
        <FormInput
          label="Email"
          name="email"
          style={{ justifyContent: 'space-between' }}
          rules={[
            {
              required: true,
              type: 'email',
              message: 'Please input valid email!',
            },
          ]}
        />

        <Form.Item
          label="Password"
          name="password"
          labelAlign="left"
          justify="space-between"
          style={{ marginBottom: '10px' }}
          rules={[
            {
              required: true,
              message: 'Please input your password!',
            },
          ]}
        >
          <Input.Password />
        </Form.Item>

        <Link to="/reset-password">Reset password</Link>

        <Form.Item style={{ margin: '15px 0 15px 0' }}>
          <Row justify="space-between">
            <Col span={11}>
              <Button style={{ width: '100%' }} type="primary" htmlType="submit">
                Submit
              </Button>
            </Col>
          </Row>
        </Form.Item>
      </Form>
    </Card>
  );
};

class Login extends PureComponent {
  render() {
    return <LoginForm />;
  }
}

export default Login;
